<template>
  <v-main id="login_view">
    <auth />
  </v-main>
</template>

<script>
import Auth from '@/components/Auth.vue'

export default {
  name: 'Login',
  components: {
    Auth,
  },
  computed: {
    computedBackground() {
      return require('@/assets/fondo.jpg')
    },
  },
}
</script>

<style lang="scss" scoped>
#login_view {
  background-image: url('~@/assets/fondo.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #0c5188;
  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #1734c1;
    opacity: 0.2;
    z-index: -1;
  }
}
</style>
