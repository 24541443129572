<template>
  <v-container id="signin-form" class="fill-height">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="8" class>
        <v-card class="card" elevation="24">
          <v-row class>
            <v-col cols="12" md="8" class="pt-6 pb-6">
              <v-card-text>
                <validation-observer v-slot="{ handleSubmit }">
                  <v-form
                    class="signin-form"
                    @submit.prevent="handleSubmit(login)"
                  >
                    <h1
                      class="text-center display-1 mb-10"
                      :class="`${bgColor}--text`"
                    >
                      Ingreso Servicio de Bienestar
                    </h1>
                    <validation-provider
                      rules="required|email"
                      v-slot="{ errors, bgColor }"
                      name="Email"
                    >
                      <v-text-field
                        id="email"
                        v-model="email"
                        label="Email"
                        name="Email"
                        append-icon="email"
                        type="email"
                        :color="bgColor"
                        :success="bgColor"
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors, bgColor }"
                      name="Contraseña"
                    >
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Contraseña"
                        name="Password"
                        :color="bgColor"
                        :append-icon="
                          password_field ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="password_field ? 'text' : 'password'"
                        @click:append="password_field = !password_field"
                        :success="bgColor"
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <div class="text-center">
                      <p
                        class="mt-3 overline no-text-decoration"
                        :class="`${bgColor}--text`"
                      >
                        Utilice sus credenciales ULagos
                      </p>
                    </div>
                    <div class="text-center mt-6">
                      <v-btn
                        type="submit"
                        :loading="isLoading"
                        large
                        :color="bgColor"
                        dark
                        >Ingresar</v-btn
                      >
                    </div>
                  </v-form>
                </validation-observer>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4" class=" vcenter" :class="`${bgColor}`">
              <div>
                <!-- <v-card-text :class="`${fgColor}--text`">
                  <h1 class="text-center headline mb-3">
                    Servicio de Bienestar
                  </h1>
                </v-card-text> -->
                <div class="text-center mb-6">
                  <img
                    :src="computeLogo"
                    height="70"
                    class="my-3"
                    contain
                    alt="ULagos logo"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
extend('email', email)
extend('required', required)
export default {
  name: 'Auth',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    bgColor: {
      type: String,
      default: 'primary',
    },
    fgColor: {
      type: String,
      default: 'white',
    },
  },
  data: () => ({
    step: 1,
    password_field: false,
    email: '',
    password: '',
    isLoading: false,
  }),
  methods: {
    login: function() {
      const username = this.email
      const password = this.password
      this.isLoading = true
      this.$store
        .dispatch('auth/login', { username, password })
        .then(() => this.$router.push('/'))
        .catch(err => {
          this.isLoading = false
          this.$store.dispatch(
            'message/error',
            err.response.data.non_field_errors.join('\n')
          )
        })
    },
  },
  computed: {
    computeLogo() {
      return require('@/assets/universidad.png')
    },
  },
}
</script>

<style scoped lang="scss">
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
#signin-form {
  max-width: 75rem;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
