<template>
  <v-app-bar
    app
    dark
    fixed
    :color="isAdmin ? 'deep-purple darken-2' : 'primary'"
    class="appbar"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  >
    <v-app-bar-nav-icon
      @click="drawerState = !drawerState"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="hidden-sm-and-down ml-0 pl-0">
      <router-link :to="{ name: 'Dashboard' }" class="text">
        BIENESTAR
      </router-link>
      <router-link :to="{ name: 'Dashboard' }">
        <img :src="computeLogo" width="90px" />
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="handleFullScreen()">
      <v-icon>fullscreen</v-icon>
    </v-btn>
    <v-menu
      class="appbar-menu-item"
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-chip class="ma-2" v-on="on" color="accent" text-color="white">
          <v-avatar left> <v-icon>mdi-account-circle</v-icon></v-avatar
          >{{ user.email }}
        </v-chip>
      </template>
      <v-list>
        <v-subheader>{{ user.get_full_name }}</v-subheader>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          @click="item.click"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="isChangingRole" persistent max-width="320">
      <v-card>
        <ValidationObserver ref="observer">
          <form>
            <v-card-title class="headline">Cambio tipo de usuario</v-card-title>
            <v-card-text
              >Seleccione el rol de usuario al que desea cambiar.
              <v-flex xs12 sm12 md12>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <v-select
                    name="user_role"
                    :items="userRoles"
                    v-model="userRole"
                    label="Rol de usuario"
                    hint="Rol de usuario"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" text @click="cancel()"
                >Cancelar</v-btn
              >
              <v-btn
                color="success darken-1"
                :loading="loadingChangeRole"
                text
                @click="changeRole()"
                >Aceptar</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import Utils from '@/utils'
export default {
  data() {
    return {
      userRole: null,
      userRoles: [],
      isChangingRole: false,
      loadingChangeRole: false,
    }
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      )
        return true
      return false
    },
    drawerState: {
      get() {
        return this.$store.getters['drawer/drawerState']
      },
      set(value) {
        return this.$store.commit('drawer/toggleDrawerState', value)
      },
    },
    user: {
      get() {
        return this.$store.getters['auth/user']
      },
      set(value) {
        return this.$store.commit('auth/user', value)
      },
    },
    active_role: {
      get() {
        if (Utils.userRoles[this.user.active_role] === undefined) {
          return 'Sin definir'
        }
        return Utils.userRoles[this.user.active_role].name
      },
    },
    items() {
      return [
        {
          icon: 'mdi-account-switch',
          href: '#',
          title: 'Cambiar rol',
          subtitle: 'Rol actual: ' + this.active_role,
          click: e => this.CreateStateDialog(),
        },
        {
          icon: 'exit_to_app',
          href: '#',
          title: 'Salir',
          click: () => {
            const vm = this
            vm.$store
              .dispatch('auth/logout')
              .then(() => {
                this.$router.push('/login')
                this.$store.dispatch(
                  'message/success',
                  'Cierre de sesión exitoso'
                )
              })
              .catch(err => console.log(err))
          },
        },
      ]
    },
    computeLogo() {
      return require('@/assets/ulagos_appbar.png')
    },
  },
  methods: {
    cancel() {
      this.isChangingRole = false
      this.$refs.observer.reset()
    },
    changeRole() {
      this.loadingChangeRole = true
      this.$refs.observer
        .validate()
        .then(() => {
          this.user.active_role = this.userRole
          this.$store.commit('auth/refresh_user', this.user)
          this.$store.dispatch(
            'message/success',
            'Cambio rol de usuario: ' + this.active_role + '. Exitoso'
          )
          this.loadingChangeRole = false
          this.isChangingRole = false
          this.$router.push('/').catch(() => {})
        })
        .catch(e => {
          this.loadingChangeRole = false
        })
    },
    CreateStateDialog() {
      const vm = this
      vm.$store.getters['auth/user'].roles.forEach(role => {
        this.userRoles.push({
          value: role.id,
          text: role.get_id_display,
        })
      })
      this.isChangingRole = true
    },
    toggleNavigationBar() {
      const vm = this
      vm.$emit('toggleNavigationBar')
    },
    handleFullScreen() {
      Utils.toggleFullScreen()
    },
  },
}
</script>
<style>
.appbar {
  font-weight: bold;
  font-size: 18px;
}

.appbar .text {
  padding-left: 15px;
  color: white;
  text-decoration: none;
}
.appbar-menu-item {
  margin-right: 0px !important;
}
</style>
