<template>
  <v-app id="Bienestar-app">
    <template v-if="isLoggedIn">
      <appbar />
      <navigation :menuItems="menus" :key="componentKey" />
      <v-main>
        <!-- Page Header -->
        <breadcrumbs />
        <!-- Page Content -->
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <page-footer />
      <v-fab-transition>
        <v-btn
          v-scroll="onScroll"
          fab="fab"
          small
          dark="dark"
          fixed="fixed"
          bottom="bottom"
          right="right"
          color="error"
          v-show="fab"
          @click="toTop"
        >
          <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <template v-else>
      <transition>
        <router-view></router-view>
      </transition>
    </template>
    <v-snackbar
      :color="message.type"
      v-model="showSnackbar"
      bottom
      :multi-line="messageIsIterable === true"
      right
      :timeout="message.closable ? 0 : 3000"
    >
      <ul v-if="messageIsIterable">
        <li v-for="(message, index) in message.message" :key="index">
          {{ message }}
        </li>
      </ul>
      <template v-else>{{ message.message }}</template>
      <template v-if="message.closable" v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import menu from '@/menu'
export default {
  name: 'App',
  components: {},
  data: () => ({
    fab: false,
    componentKey: 0,
  }),
  created: function() {
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('auth/logout')
        }
        throw err
      })
    })
  },
  watch: {
    active_role(newValue, oldValue) {
      this.menus = menu.Menu(newValue)
      this.forceRerender()
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    onScroll() {
      if (typeof window === 'undefined') return

      const top = window.pageYOffset || document.documentElement.offsetTop || 0

      this.fab = top > 300
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.getters['auth/user']
      },
    },
    isLoggedIn: function() {
      if (this.$store.getters['auth/isLoggedIn']) {
        return true
      }
      return false
    },
    message() {
      return this.$store.state.message
    },
    showSnackbar: {
      get() {
        return this.$store.state.message.show
      },
      set(value) {
        this.$store.commit('message/setShow', value)
      },
    },
    messageIsIterable() {
      return ['object', 'array'].includes(typeof this.message.message)
    },
    active_role: {
      get() {
        return this.$store.getters['auth/user'].active_role
      },
    },
    menus: {
      get() {
        return menu.Menu(this.active_role)
      },
      set(value) {
        menu.Menu(value)
      },
    },
  },
}
</script>
