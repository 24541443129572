function parseMessage (message, type) {
  if (typeof message === 'object') {
    return Object.assign(
      {
        closable: false
      },
      {
        ...message,
        type
      }
    )
  }
  return { message, type }
}

export default {
  error ({ commit }, message) {
    commit('push', parseMessage(message, 'error'))
  },
  info ({ commit }, message) {
    commit('push', parseMessage(message, 'info'))
  },
  warning ({ commit }, message) {
    commit('push', parseMessage(message, 'warning'))
  },
  success ({ commit }, message) {
    commit('push', { message, type: 'success' })
  }
}
