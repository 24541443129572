<template>
  <div class="dashboard d-md-flex" fluid>
    <v-col cols="12" md="7">
      <v-card loading>
        <template slot="progress">
          <v-progress-linear color="deep-purple" value="100">
          </v-progress-linear>
        </template>
        <v-card-title>
          Plataforma de administración
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          Para operar en la plataforma, debe ingresar a las distintas opciones
          en el menú lateral.</v-card-text
        >
      </v-card>
    </v-col>
    <v-col cols="12" md="5">
      <v-card loading>
        <template slot="progress">
          <v-progress-linear
            color="accent"
            value="100"
            :indeterminate="loadingAM"
          >
          </v-progress-linear>
        </template>
        <v-card-title>Mis Datos</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-title class="text-left text-wrap"
                >Nombre</v-list-item-title
              >
              <v-list-item-subtitle class="text-right text-wrap">
                {{ aboutMe.name }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left text-wrap"
                >Rut</v-list-item-title
              >
              <v-list-item-subtitle class="text-right text-wrap">
                {{ aboutMe.run | rut_without_dv }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left text-wrap"
                >Email</v-list-item-title
              >
              <v-list-item-subtitle class="text-right text-wrap">
                {{ aboutMe.email }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left text-wrap"
                >Rol activo de Usuario</v-list-item-title
              >
              <v-list-item-subtitle class="text-right text-wrap">
                {{ aboutMe.role }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import Utils from '@/utils'
export default {
  name: 'DashboardAdmin',
  data() {
    return {
      loadingAM: false,
      aboutMe: {
        name: '',
        run: '',
        email: '',
        role: '',
      },
    }
  },
  created() {
    this.AboutMe()
  },
  computed: {},
  methods: {
    AboutMe() {
      this.loadingAM = true
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(
            this.$api('/about_me/' + this.$store.getters['auth/user'].id),
            {}
          )
          .then(({ data }) => {
            this.aboutMe.name = data.get_full_name
            this.aboutMe.email = data.email
            this.aboutMe.run = data.get_run
            this.aboutMe.role =
              Utils.userRoles[this.$store.getters['auth/user'].active_role].name
          })
          .catch((error) => {
            this.$emit('error', error)
          })
          .then(() => {
            return this.$nextTick(() => {
              this.loadingAM = false
            })
          })
      }
    },
  },
}
</script>

<style lang="scss"></style>
