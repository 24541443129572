export default [
  {
    path: '',
    name: 'ms_categories.index',
    meta: {
      breadcrumb: [
        { name: 'Categorias de servicios médicos' }
      ]
    },
    component: () => import('@/views/ms_categories/MSCategorieIndex.vue')
  }
]
