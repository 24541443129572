import utils from '@/utils'
import affiliateMenu from './affiliate'
import adminMenu from './admin'
import nonAffiliate from './new-user'

export function Menu (userRole) {
  // eslint-disable-next-line eqeqeq
  if (userRole == utils.userRoles[1].id) {
    return affiliateMenu
  }
  if (userRole === undefined) {
    return nonAffiliate
  }
  // eslint-disable-next-line eqeqeq
  if (userRole == utils.userRoles[2].id) {
    return adminMenu
  }
}

export default {
  Menu
}
