<template>
  <div class="dashboard d-flex" fluid>
    <v-col cols="12" md="9">
      <v-card loading class="mb-4">
        <template slot="progress">
          <v-progress-linear color="deep-purple" value="100" :indeterminate="loadingCC">
          </v-progress-linear>
        </template>
        <v-card-title>
          Cuenta corriente - Bonificaciones
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab small outlined :loading="loadingCC" v-bind="attrs" v-on="on"
                @click="reloadCC()">
                <v-icon>mdi-replay</v-icon>
              </v-btn>
            </template>
            <span>Actualizar saldos</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <widget icon="account_balance" :title="widgets.benefit.amount" :subTitle="widgets.benefit.percentage"
                :supTitle="'Asignación ANUAL'" color="#2196F3" />
            </v-col>
            <v-col>
              <widget icon="money_off" :title="widgets.debit.amount" :subTitle="widgets.debit.percentage"
                :supTitle="'Total UTILIZADO'" color="#dc3545" />
            </v-col>
            <v-col>
              <widget icon="account_balance_wallet" :title="widgets.balance.amount"
                :subTitle="widgets.balance.percentage" :supTitle="'Saldo DISPONIBLE'" color="#00b297" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="12" md="4" class="inner_cols">
          <v-card loading class="">
            <template slot="progress">
              <v-progress-linear color="accent" value="100">
              </v-progress-linear>
            </template>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="headline" v-text="lipigas.title"></v-card-title>

                <v-card-subtitle v-text="lipigas.subtitle"></v-card-subtitle>

                <v-card-actions>
                  <v-btn text :href="lipigas.route" target="_blank" color="deep-purple accent-4">
                    Solicitalo aquí
                  </v-btn>
                </v-card-actions>
              </div>

            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="inner_cols">
          <v-card loading class="">
            <template slot="progress">
              <v-progress-linear color="accent" value="100">
              </v-progress-linear>
            </template>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="headline" v-text="soporte.title"></v-card-title>

                <v-card-subtitle v-text="soporte.subtitle"></v-card-subtitle>

                <v-card-actions>
                  <v-btn text :href="soporte.route" target="_blank" color="deep-purple accent-4">
                    Ingresar al formulario
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="inner_cols">
          <v-card loading class="">
            <template slot="progress">
              <v-progress-linear color="accent" value="100">
              </v-progress-linear>
            </template>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="headline" v-text="rendimientoEscolar.title"></v-card-title>

                <v-card-subtitle v-text="rendimientoEscolar.subtitle"></v-card-subtitle>

                <v-card-actions>
                  <v-btn text :href="rendimientoEscolar.route" target="_blank" color="deep-purple accent-4">
                    Ingresar al formulario
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="6">
          <a href="/manual-beneficios-2024.pdf" target="_blank">
            <img alt="Descargar manual de beneficios 2024" src="../../assets/banner_manual.jpg" class="banner">
          </a>
        </v-col>
        <v-col cols="6">
          <a href="https://seguros.sura.cl/vida-salud/seguro-vive-oncologico" target="_blank">
            <img alt="Seguro vive oncológico" src="~@/assets/banner_promo.jpg" class="banner">
          </a>
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <v-col cols="12">
      <v-card loading>
      <template slot="progress">
      <v-progress-linear color="accent" value="100" :indeterminate="loadingAM">
      </v-progress-linear>
      </template>
      <v-card-title>Mis Datos</v-card-title>
      <v-card-text>
      <v-list>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Nombre</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.name }}
      </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Rut</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.run | rut_without_dv }}
      </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Email</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.email }}
      </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Campus - Sede</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.campus }}
      </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Jornada Laboral</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.workday }}
      </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
      <v-list-item-title class="text-left text-wrap">Rol activo</v-list-item-title>
      <v-list-item-subtitle class="text-right text-wrap">
      {{ aboutMe.role }}
      </v-list-item-subtitle>
      </v-list-item>
      </v-list>
      </v-card-text>
      </v-card>
    </v-col>
        <v-col cols="12">
          <a href="https://www.scotiabankchile.cl/convenios-scotia" target="_blank">
            <img src="~@/assets/banner_scotia.jpg" class="banner">
          </a>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import Utils from '@/utils'
export default {
  name: 'DashboardAffiliate',
  data() {
    return {
      loadingCC: false,
      loadingAM: false,
      rendimientoEscolar: {
        title: 'Rendimiento Escolar',
        subtitle: 'Solicitud de beneficio - Premiación rendimiento escolar  año 2022',
        route: 'https://forms.gle/cTpoeJpK4yfnNMdE9',
      },
      soporte: {
        title: 'Ayuda',
        subtitle: 'Si presenta algún inconveniente complete el formulario',
        route: 'https://forms.gle/zfV3gHV6wR6o7CK87',
      },
      lipigas: {
        title: 'Solicita Gas',
        subtitle: 'Convenio Lipigas - Servicio de Bienestar ULagos.',
        route:
          'https://docs.google.com/forms/d/e/1FAIpQLSed-Rm0PocpAVXvOX1BCI8m_6Fma_ed1l_BMAUNyboGuACqNA/closedform',
      },
      widgets: {
        benefit: {
          amount: '0',
          percentage: 'Total',
        },
        debit: {
          amount: '0',
          percentage: '0',
        },
        balance: {
          amount: '0',
          percentage: '0',
        },
      },
      aboutMe: {
        name: '',
        run: '',
        email: '',
        role: '',
        campus: '',
        workday: '',
      },
    }
  },
  created() {
    this.reloadCC()
    this.AboutMe()
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      )
        return true
      return false
    },
    lipigas_img() {
      return require('@/assets/600X580_lipigas.png')
    },
  },
  methods: {
    reloadCC() {
      this.loadingCC = true
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(this.$api('/current_account/balance/'), {})
          .then(({ data }) => {
            this.widgets.benefit.amount = this.$options.filters.currency(
              data.benefit.amount
            )
            this.widgets.debit.amount = this.$options.filters.currency(
              data.debit.amount
            )
            this.widgets.debit.percentage =
              'Utilizado: ' + data.debit.percentage.toFixed(2).toString() + '%'
            this.widgets.balance.amount = this.$options.filters.currency(
              data.balance.amount
            )
            this.widgets.balance.percentage =
              'Disponible: ' +
              data.balance.percentage.toFixed(2).toString() +
              '%'
          })
          .catch((error) => {
            this.$emit('error', error)
          })
          .then(() => {
            return this.$nextTick(() => {
              this.loadingCC = false
            })
          })
      }
    },
    AboutMe() {
      this.loadingAM = true
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(
            this.$api('/about_me/' + this.$store.getters['auth/user'].id),
            {}
          )
          .then(({ data }) => {
            this.aboutMe.name = data.get_full_name
            this.aboutMe.email = data.email
            this.aboutMe.run = data.get_run
            this.aboutMe.role =
              Utils.userRoles[this.$store.getters['auth/user'].active_role].name
            if (data.get_affiliate_profile != null) {
              this.aboutMe.campus = data.get_affiliate_profile.campus
              this.aboutMe.workday =
                data.get_affiliate_profile.get_workday_display
            }
          })
          .catch((error) => {
            this.$emit('error', error)
          })
          .then(() => {
            return this.$nextTick(() => {
              this.loadingAM = false
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
.inner_cols {
  padding-top: 12px !important;
}

.dashboard {
  margin-bottom: 12px;
}

img.banner {
  border: 5px solid #00b1b7;
  width: 100%;
}
</style>
