import axios from 'axios'
import config from '@/config'
import utils from '@/utils'

function processAuth (response, commit) {
  const token = response.data.token
  const user = response.data.user
  var BreakException = {}
  try {
    user.roles.reverse().forEach(role => {
      // eslint-disable-next-line eqeqeq
      if (role.id == utils.userRoles[role.id].id) {
        user.active_role = role.id
        throw BreakException
      }
    })
  } catch (e) {
    if (e !== BreakException) throw e
  }
  localStorage.setItem('token', token)
  axios.defaults.headers.common.Authorization = 'token ' + token
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('authStatus', 'success')
  commit('auth_success', { token, user })
}

export default {
  login ({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: config.api('auth/'), data: user, method: 'POST' })
        .then(resp => {
          processAuth(resp, commit)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('authStatus')
      delete axios.defaults.headers.common.Authorization
      resolve()
    })
  },
}
