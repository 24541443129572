/**
 * Estados de solicitudes de bonificación
 */

export default {
  DRAFT: {
    state: 'Borrador',
    id: 1,
  },
  SEND: {
    state: 'Enviado',
    id: 2,
  },
  APPROVED: {
    state: 'Aprobado',
    id: 3,
  },
  REJECTED: {
    state: 'Rechazado',
    id: 4,
  },
  REQUEST_CLARIFICATION: {
    state: 'Solicita aclaración',
    id: 5,
  },
  PAID: {
    state: 'Pagado',
    id: 6,
  },
}
