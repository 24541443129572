import Vue from 'vue'
import 'babel-polyfill'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/'
import vuetify from '@/plugins/vuetify'
import config from '@/config'
import '@/plugins/axios'
import * as VeeValidate from 'vee-validate'
import {
  localize,
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import { between, integer } from 'vee-validate/dist/rules'
import { setupComponents } from '@/config/setup-components'
import { setupFilters } from './config/setup-filters'
import Router from 'vue-router'
import CSRF_TOKEN from './csrf_token.js'
import moment from 'moment'

// Install and Activate the Spanish locale for Vee validate.
localize('es', es)
// Setup general use components
setupComponents(Vue)
// Setup general use fitlers
setupFilters(Vue)
// Setup VeeValidate components
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
// Add specific rules
extend('between', between)
extend('integer', integer)
Vue.axios.defaults.headers.common['X-CSRFToken'] = CSRF_TOKEN
const token = localStorage.getItem('token')
if (token) {
  Vue.axios.defaults.headers.common.Authorization = 'token ' + token
}

// Override Int.NumberFormat method format
;(function() {
  // Obtenemos la definicion de la funcion original
  var prop = Object.getOwnPropertyDescriptor(
    Intl.NumberFormat.prototype,
    'format'
  )

  // Sobrescribimos el método "format"
  Object.defineProperty(Intl.NumberFormat.prototype, 'format', {
    get: function() {
      return function(value) {
        var fn = prop.get.call(this) // Recuperamos la funcion "formateadora" original
        var opts = this.resolvedOptions() // Obtenemos las opciones de "formateo"

        // Solo cambiamos el formateo cuando es moneda en español y el numero es >= 1.000 o menor a 10.000
        if (
          opts.style === 'currency' &&
          opts.locale.substr(0, 2) === 'es' &&
          opts.numberingSystem === 'latn' &&
          value >= 1000 &&
          value < 10000
        ) {
          var num = fn(10000) // -> [pre]10[sep]000[sub]
          var pre = num.substr(0, num.indexOf('10'))
          var sep = num.substr(num.indexOf('10') + 2, 1)
          var sub = num.substr(num.indexOf('000') + 3)
          num = value.toString()
          return pre + num.slice(0, 1) + sep + num.slice(1) + sub
        }
        // Sino devolvemos el número formateado normalmente
        return fn(value)
      }
    },
  })
})()

// Fix issue https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

extend('before', {
  params: ['arg'],
  validate: (value, { arg }) => {
    const [day, month, year] = value.split('/')
    const [day2, month2, year2] =
      arg === null || arg === undefined
        ? '00/00/0000'.split('/')
        : arg.split('/')
    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').isSameOrBefore(
      moment(`${year2}-${month2}-${day2}`, 'YYYY-MM-DD')
    )
  },
  message: 'Fecha desde debe ser menor a Fecha hasta',
})

Vue.config.productionTip = false
Vue.prototype.$api = config.api
Vue.prototype.$apiWithoutSlash = config.apiWithoutSlash

new Vue({
  vuetify,
  router,
  store,
  VeeValidate,
  render: h => h(App),
}).$mount('#app')
