<template>
  <div id="Bonus">
    <router-view></router-view>
    <div v-if="currentRouteName != name" class="mt-2">
      <v-btn color="primary" @click="goHome">Volver</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: 'bonus.index'
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    goHome () {
      this.$router.push({ name: this.name }).catch(() => {})
    }
  }
}
</script>
