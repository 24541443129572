/**
 * Affiliate's menu items
 * */
export default [
  { icon: 'mdi-view-dashboard', model: true, text: 'Inicio', path: '/' },
  { heading: 'Beneficios institucionales' },
  { icon: 'mdi-gift', model: false, text: 'Beneficios anuales', path: '/benefits', },
  {
    icon: 'mdi-chevron-up',
    'icon-alt': 'mdi-chevron-down',
    text: 'Servicios médicos',
    model: false,
    children: [
      { icon: 'mdi-margin', model: false, text: 'Categorias de servicios médicos', path: '/ms_categories' },
      { icon: 'mdi-needle', model: false, text: 'Prestaciones médicas', path: '/medical_services' },
    ],
  },
  { heading: 'Bonificaciones' },
  { icon: 'mdi-book-multiple', model: false, text: 'Mis Solicitudes de Bonificación', path: '/bonus' },
  { heading: 'Prestamos' }

]
