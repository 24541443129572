import qs from 'qs'
import normalize from 'normalize-url'

const apiBase = process.env.VUE_APP_BASE_URL
  ? `${process.env.VUE_APP_BASE_URL}/api/`
  : 'http://localhost:8000/api/'

const apiBaseMedias = process.env.VUE_APP_BASE_URL
  ? `${process.env.VUE_APP_BASE_URL}/`
  : 'http://localhost:8000/'

function normalizeUrl(url) {
  return `${normalize(url, {
    removeTrailingSlash: true,
  })}`
}

export function api(path, queryParameters = null) {
  if (queryParameters === null) {
    return `${normalizeUrl(`${apiBase}${path}`)}/`
  }

  return `${normalizeUrl(`${apiBase}${path}`)}${
    path.includes('?') ? '&' : '?'
  }${qs.stringify(queryParameters)}`
}

export function apiWithoutSlash(path, queryParameters = null) {
  if (queryParameters === null) {
    return `${normalizeUrl(`${apiBaseMedias}${path}`)}`
  }

  return `${normalizeUrl(`${apiBase}${path}`)}${
    path.includes('?') ? '&' : '?'
  }${qs.stringify(queryParameters)}`
}
export default {
  api,
  apiWithoutSlash,
}
