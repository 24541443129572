export default [
  {
    path: '',
    name: 'search_affiliate.index',
    meta: {
      breadcrumb: [{ name: 'Afiliados en plataforma Delfos' }],
    },
    component: () => import('@/views/affiliates/SearchAffiliateIndex.vue'),
  },
]
