// Core Components
import Appbar from '../components/core/Appbar.vue'
import Navigation from '../components/core/NavigationDrawer.vue'
import Breadcrumbs from '../components/core/Breadcrumbs.vue'
import PageFooter from '../components/core/PageFooter.vue'

import Widget from '../components/Widget.vue'

function setupComponents(Vue) {
  Vue.component('appbar', Appbar)
  Vue.component('navigation', Navigation)
  Vue.component('breadcrumbs', Breadcrumbs)
  Vue.component('page-footer', PageFooter)
  Vue.component('widget', Widget)
}

export { setupComponents }
