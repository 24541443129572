export default [
  {
    path: '',
    name: 'ms_bonus.index',
    meta: {
      breadcrumb: [
        { name: 'Bonificación de servicios médicos' }
      ]
    },
    component: () => import('@/views/ms_bonus/MSBonusIndex.vue')
  }
]
