export default [
  {
    path: '',
    name: 'bonus.index',
    meta: {
      breadcrumb: [
        { name: 'Solicitudes de bonificación' }
      ]
    },
    component: () => import('@/views/bonus/BonusIndex.vue')
  },
  {
    path: ':id',
    name: 'bonus.show',
    meta: {
      breadcrumb: [
        { name: 'Bonificación detalle' }
      ]
    },
    component: () => import('@/views/bonus/BonusShow.vue')
  },
  {
    path: ':id/edit',
    name: 'bonus.edit',
    meta: {
      breadcrumb: [
        { name: 'Bonificación edición' }
      ]
    },
    component: () => import('@/views/bonus/BonusEdit.vue')
  }
]
