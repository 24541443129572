<template>
  <div id="MedicalServices">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  methods: {
    goHome () {
      this.$router.push({ name: 'medical_service.index' })
    }
  },
  computed: {
  }
}
</script>
