import userGroups from '@/utils/user-groups'
import userRoles from '@/utils/user-roles'
import BonusStates from '@/utils/bonus-states'

const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const kebab = str => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const toggleFullScreen = () => {
  const doc = window.document
  const docEl = doc.documentElement

  const requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen
  const cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

// Return a date in format dd-mm-yyyy
const formatDate = date => {
  if (!date) return null
  const [year, month, day] = date.split('-')
  return `${day}-${month}-${year}`
}

// Return a date in format yyy-mm-dd
const formatDateReverse = date => {
  if (!date) return null
  const [day, month, year] = date.split('-')
  return `${year}-${month}-${day}`
}

export default {
  randomElement,
  toggleFullScreen,
  kebab,
  formatDate,
  formatDateReverse,
  userGroups,
  userRoles,
  BonusStates,
}
