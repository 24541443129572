export default {
  auth_request (state) {
    state.status = 'loading'
  },
  auth_success (state, { token, user }) {
    state.status = 'success'
    state.token = token
    state.user = user
  },
  refresh_user (state, user) {
    state.status = 'success'
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  auth_error (state) {
    state.status = 'error'
  },
  logout (state) {
    state.status = ''
    state.token = ''
    state.user = {}
  },
}
