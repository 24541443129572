<template>
  <div v-if="isAdmin">
    <dashboard-admin />
  </div>
  <div v-else-if="isAffiliate">
    <dashboard-affiliate />
  </div>
  <div v-else>
    <dashboard-blank />
  </div>
</template>

<script>
import Utils from '@/utils'
import DashboardAdmin from '@/components/dashboards/DashboardAdmin'
import DashboardAffiliate from '@/components/dashboards/DashboardAffiliate'
import DashboardBlank from '@/components/dashboards/DashboardBlank'
export default {
  name: 'DashboardBase',
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      )
        return true
      return false
    },
    isAffiliate() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[1].id
      )
        return true
      return false
    },
  },
  methods: {},
  components: {
    DashboardAdmin,
    DashboardAffiliate,
    DashboardBlank,
  },
}
</script>

<style lang="scss"></style>
