import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/'

Vue.use(VueAxios, axios)

function manageResponseError (response) {
  if (response.status >= 500) {
    store.dispatch('message/error', 'Hubo un error en el servidor. Vuelva a intentar más tarde')
  } else if (response.status === 400 || response.status === 409) {
    if (typeof response.data === 'object') {
      store.dispatch('message/warning', {
        message: response.data.detail ? response.data.detail : response.data,
        closable: true
      })
    } else if (response.status === 400) {
      store.dispatch('message/warning', 'Hubo un error con los datos enviados. Favor revise los datos ingresados')
    } else if (response.status === 409) {
      store.dispatch(
        'message/warning',
        'El estado del recurso no admite realizar la operación. Favor revise que todo esté en orden'
      )
    }
  } else {
    store.dispatch('message/error', 'Hubo un problema al realizar la petición. Vuelva a intentar más tarde')
  }
}

Vue.axios.interceptors.response.use(null, error => {
  if (error.response) {
    manageResponseError(error.response)
  }

  return Promise.reject(error)
})
