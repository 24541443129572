<template>
  <v-navigation-drawer
    v-model="drawerState"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list dense>
      <template v-for="item in menuItems">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
          </v-col>
          <v-col cols="6" class="text-center"></v-col>
        </v-row>
        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.path"
            link
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="item.text"
          link
          v-model="item.model"
          color="primary"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Utils from '@/utils'
export default {
  data() {
    return {
      selectedIndex: 1,
    }
  },
  props: {
    menuItems: [String, Array, Object],
  },
  computed: {
    user: {
      get() {
        return this.$store.getters['auth/user']
      },
      set(value) {
        return this.$store.commit('auth/user', value)
      },
    },
    active_role() {
      return Utils.userRoles[this.user.active_role].name
    },
    drawerState: {
      get() {
        return this.$store.getters['drawer/drawerState']
      },
      set(v) {
        return this.$store.commit('drawer/toggleDrawerState', v)
      },
    },
  },
}
</script>
