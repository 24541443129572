import Bonus from './bonus'
import Benefits from './benefits'
import MedicalServices from './medical-services'
import MSCategories from './ms-categories'
import MSBonus from './ms-bonus'
import Login from '@/views/Login.vue'
import Affiliates from './affiliates'

import DashboardView from '@/views/Dashboard.vue'
import ErrorView from '@/views/Error.vue'
import BonusBaseView from '@/views/bonus/BonusBase.vue'
import BenefitBaseView from '@/views/benefits/BenefitBase.vue'
import MedicalServiceBaseView from '@/views/medical_services/MedicalServiceBase.vue'
import MSCategoriesBaseView from '@/views/ms_categories/MSCategorieBase.vue'
import MSBonusBaseView from '@/views/ms_bonus/MSBonusBase.vue'
import AffiliateBaseView from '@/views/affiliates/SearchAffiliateBase.vue'
export default [
  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/404',
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Inicio' }],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: ErrorView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/bonus',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Bonificaciones' }],
    },
    component: BonusBaseView,
    children: Bonus,
  },
  {
    path: '/benefits',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Beneficios' }],
    },
    component: BenefitBaseView,
    children: Benefits,
  },
  {
    path: '/medical_services',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Servicios médicos' }],
    },
    component: MedicalServiceBaseView,
    children: MedicalServices,
  },
  {
    path: '/ms_categories',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Categorias de servicios médicos' }],
    },
    component: MSCategoriesBaseView,
    children: MSCategories,
  },
  {
    path: '/ms_bonus',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Bonificaciones de servicios médicos' }],
    },
    component: MSBonusBaseView,
    children: MSBonus,
  },
  {
    path: '/affiliates',
    meta: {
      requieresAuth: true,
    },
    component: AffiliateBaseView,
    children: Affiliates,
  },
]
