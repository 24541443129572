// Some filters

function setupFilters(Vue) {
  // Percentage filter
  Vue.filter('percentage', function(value, decimals) {
    if (!value) {
      value = 0
    }
    if (!decimals) {
      decimals = 2
    }
    value = value * 100
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = value + '%'
    return value
  })
  // Curerency formatter filter
  Vue.filter('currency', function(value) {
    if (!value) {
      value = 0
    }
    const formatter = new Intl.NumberFormat('es-CL', {
      currency: 'CLP',
      style: 'currency',
    })
    return formatter.format(value)
  })

  // chilean RUT formatter filter
  Vue.filter('rut_without_dv', function(value) {
    if (!value) {
      return ''
    }
    var result = value.slice(-4, -1) + '-' + value.substr(value.length - 1)
    for (var i = 4; i < value.length; i += 3) {
      result = value.slice(-3 - i, -i) + '.' + result
    }

    return result
  })
}

export { setupFilters }
