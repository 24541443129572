export default [
  {
    path: '',
    name: 'medical_service.index',
    meta: {
      breadcrumb: [
        { name: 'Servicios médicos' }
      ]
    },
    component: () => import('@/views/medical_services/MedicalServiceIndex.vue')
  }
]
