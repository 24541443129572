/**
 * Grupos de Usuarios ordenados por prioridad
 */
export default [
  {
    name: 'Administrador',
    id: 2,
    title: 'Administrador',
    color: 'light-green',
    icon: 'supervisor_account'
  },
  {
    name: 'Afiliado',
    id: 1,
    title: 'Afiliado',
    color: 'light-blue',
    icon: 'account_circle'
  }
]
