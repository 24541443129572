import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import message from './message'
import drawer from './drawer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    message: {
      namespaced: true,
      ...message
    },
    drawer: {
      namespaced: true,
      ...drawer
    }
  }
})
