import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// Translation provided by Vuetify (javascript)
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md' || 'fa',
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#0055b7',
        secondary: '#424242',
        accent: '#00b1b7', // '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#0055b7',
        secondary: '#424242',
        accent: '#00b1b7',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
})
