let i = 0

export default {
  push (state, { message = 'Error', type = 'info', closable = false } = {}) {
    state.id = i++
    state.message = message
    state.type = type
    state.show = true
    state.closable = closable
  },
  setShow (state, show) {
    state.show = show
  }
}
