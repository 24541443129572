<template>
  <div id="Bonus" v-if="isAdmin">
    <router-view></router-view>
  </div>
</template>

<script>
import Utils from '@/utils'
export default {
  methods: {
    goHome() {
      this.$router.push({ name: 'bonus.index' })
    },
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return true
      }
      return false
    },
  },
}
</script>
