/**
 * Roles de Usuarios ordenados por prioridad
 */
export default {
  2: {
    name: 'Administrador',
    id: 2,
    title: 'Administrador',
    color: 'light-green',
    icon: 'supervisor_account'
  },
  1: {
    name: 'Afiliado',
    id: 1,
    title: 'Afiliado',
    color: 'light-blue',
    icon: 'account_circle'
  }
}
