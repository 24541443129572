<template>
  <v-card class="widget-card">
    <v-card-text class="pa-0 d-inline-flex">
      <v-container class="pa-0">
        <v-row class="layout  ma-0" :style="{ backgroundColor: color }">
          <v-col xs="12" md="3" class="flex">
            <div class="layout justify-center align-center">
              <v-icon class="widget-icon" size="35px">{{ icon }}</v-icon>
            </div>
          </v-col>
          <v-col xs="12" md="9" class=" widget-text ma-0 justify-center">
            <span class="caption">{{ subTitle }}</span>
            <div class="headline">{{ title }}</div>
            <span class="caption">{{ supTitle }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    supTitle: {
      type: [String, Number],
      required: false,
    },
    subTitle: {
      type: [String, Number],
      required: false,
    },
    title: {
      type: [String, Number],
      required: false,
    },
    icon: {
      type: [String, Number],
      required: false,
    },
    color: {
      type: [String, Number],
      required: false,
    },
  },

  data() {
    return {}
  },
}
</script>

<style lang="scss">
.widget-card {
  border-radius: 5%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent !important;
  .widget-icon {
    color: white;
    opacity: 0.8;
  }
  .widget-text {
    span {
      font-size: 0.85rem !important;
    }
    color: white;
    padding: 12px;
  }
}
</style>
