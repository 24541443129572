<template>
  <v-footer absolute inset class="pa-2" dark app>
    <small class="white--text"
      >DIRECCIÓN DE GESTIÓN DEL DESARROLLO HUMANO - ULAGOS &copy;
      {{ new Date().getFullYear() }}</small
    >
  </v-footer>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style></style>
