/**
 * Admin's menu items
 * */
export default [
  { icon: 'mdi-view-dashboard', model: true, text: 'Inicio', path: '/' },
  { heading: 'Administración' },
  {
    icon: 'mdi-account-search-outline',
    model: false,
    text: 'Consulta afiliados',
    path: '/affiliates',
  },
  { heading: 'Beneficios institucionales' },
  {
    icon: 'mdi-gift',
    model: false,
    text: 'Beneficios anuales',
    path: '/benefits',
  },
  {
    icon: 'mdi-chevron-up',
    'icon-alt': 'mdi-chevron-down',
    text: 'Servicios médicos',
    model: false,
    children: [
      {
        icon: 'mdi-margin',
        model: false,
        text: 'Categorias de servicios médicos',
        path: '/ms_categories',
      },
      {
        icon: 'mdi-needle',
        model: false,
        text: 'Prestaciones médicas',
        path: '/medical_services',
      },
      {
        icon: 'mdi-margin',
        model: false,
        text: 'Bonificación de servicios',
        path: '/ms_bonus',
      },
    ],
  },
  { heading: 'Bonificaciones' },
  {
    icon: 'mdi-book-multiple',
    model: false,
    text: 'Solicitudes de Bonificación',
    path: '/bonus',
  },
  { heading: 'Prestamos' },
]
