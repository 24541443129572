export default [
  {
    path: '',
    name: 'benefit.index',
    meta: {
      breadcrumb: [{ name: 'Beneficios Servicio de Bienestar' }],
    },
    component: () => import('@/views/benefits/BenefitIndex.vue'),
  },
]
